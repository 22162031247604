.banner{
    padding-top: 7%;
    position: relative;
    padding-bottom: 3%;
    z-index: 3;
    .content{
        display: flex;
        
        .left{
            width: 65%;
            padding-top: 4%;

            h1{
                color: #fff;
                font-weight: 400;
                font-size: 40px;
                width: 600px;

                span{
                    color: #39d4ba;
                }
            }

            p{
                color: #ffffff91;
                font-size: 16px;
                width: 600px;
                margin-top: 10px;
            }

            .line{
                width: 150px;
                height: 3px;
                background-color: #39d4ba6e;
                border-radius: 10px;
                margin-top: 10px;
            }
        }
        .right{
            width: 35%;

            img{
                width: 310px;
                margin: auto;
                display: block;
                border-bottom-left-radius: 25%;
                border-bottom-right-radius: 25%;
                filter: drop-shadow(0 0 0.8rem #39d4bad3);
                transition: 0.4s ease;

                &:hover{
                    filter: drop-shadow(0 0 1rem #4deff1);
                    transition: 0.4s ease;
                    scale: 1.05;
                }
            }
        }
    }
}

@media screen and (max-width: 850px) and (min-width: 360px){
    .banner{
        width: auto;

        .content{
            flex-direction: column;

            .left{
                width: 100%;

                h1{
                    width: 100%;
                    text-align: center;
                    font-size: 24px;
                }

                p{
                    width: 100%;
                    text-align: center;
                    font-size: 16px;
                }

                .line{
                    width: 100%;
                }
            }
            .right{
                width: 100%;

                img{
                    width: 250px;
                    margin: auto;
                    display: block;
                    margin-top: 10%;
                }
            }
        }
    }
}