@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

.blinking {
    animation: blink 1s infinite;
}

.typing {
    margin-top: 2%;
    color: #ffffff;
    font-size: 32px;
    
}

@media screen and (max-width: 850px) and (min-width: 360px){
    .typing{
        font-size: 24px;
    }
}