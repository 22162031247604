.contact{
    .content{
        margin-top: 5%;
        text-align: center;
        a{
            text-decoration: none;
            padding: 10px;
            text-align: center;
        }
    }
}