body {
    background: rgb(4,4,4);
    background: linear-gradient(270deg, rgba(4,4,4,1) 0%, rgba(29,29,29,1) 61%, rgba(32,32,32,1) 78%, rgba(51,51,51,1) 100%);
}

*{
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    
}

::-webkit-scrollbar {
    width: 10px;
    height: 20px; 
}

::-webkit-scrollbar-thumb{
    background-color: #39d4ba;
}

